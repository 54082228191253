<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>스페이스 정보가 수정되었습니다.</h3>
            <div class="single-file-check">
                <p @click="updateSpace()">확인</p>
            </div>
        </div>
    </div>
</template>

<script>
import router from "@/router";

export default {
  name: 'updateSpaceModal',
  methods: {
    updateSpace () {
      router.push({ name: "MySpace" });
      this.$emit('closeUpdateSpace');
    }
  }
};
</script>
