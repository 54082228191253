<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3 v-html="spaceInfo.spaceNm + '의<br/> 구독을 해지하시겠습니까?'" />
            <p class="single-file-content" v-if="spaceInfo.checkMusicHistory === 'Y'">
                구독을 해지하면 플레이어를 이용할 수 없어요.<br />
                서비스 만료기간까지는 플레이어를 이용할 수 있어요.
            </p>
            <p class="single-file-content" v-else>구독을 해지하면 플레이어를 이용할 수 없어요.</p>
            <div class="single-file-btn">
                <p @click="$emit('closeModal')">아니오</p>
                <p :class="{ disabled: disabledInsert === true }" id="color-text" @click="cancelPayment">네</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import VueCookies from 'vue-cookies';
import axios from 'axios';

export default defineComponent({
  emits: ['canclepayment'],
  name: 'unsubscribeModal',
  data () {
    return {
      disabledInsert: false
    };
  },
  props: ['spaceInfo'],
  methods: {
    cancelPayment () {
      this.disabledInsert = true;
      if (this.spaceInfo.spaceType === 'Building') {
        // 건물형 스페이스인 경우 바로 구독 해지
        this.unsubscribeSpace();
      } else {
        this.$emit('canclepayment');
      }
    },
    unsubscribeSpace () {
      var spaceId = this.spaceInfo.spaceId;
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/cancleSubscription', { spaceId }, { headers })
        .then((res) => {
          axios
            .post('/api/menu/disconnectSpaceNotice', { spaceId }, { headers })
            .then((res) => {
              console.log(res.data.result);
            })
            .catch((err) => {
              console.log(err);
            });

          // console.log(res.data.result);
          if (spaceId === VueCookies.get('spaceId')) {
            // 음악 멈춤
            var video = document.getElementById('video');
            video.pause();
            this.$store.commit('setPlay', false);
            // 쿠키 spaceId 삭제
            VueCookies.remove('spaceId');
            // vex spaceId, spaceNm, musicList 삭제
            this.$store.commit('setSpaceId', null);
            this.$store.commit('setSpaceNm', null);
            // this.$store.commit("setMusicList", null);
          }
          this.$router.push('/myspace');
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
});
</script>

<style scoped>
.disabled {
  pointer-events: none;
}

.single-file-box h3 {
  line-height: 1.3;
}
</style>
