<template>
    <div class="wrap">
        <!-- <Floating :currentMusic="this.$store.state.musicList[this.$store.state.currentMusicIdx]" :isPlay="this.$store.state.isPlay === '' ? false : this.$store.state.isPlay" /> -->
        <searchStore
            v-if="isMap"
            @closeMap="isMap = false"
            @getSpaceInfo="setSpaceInfo"
            :spaceName="spaceName"
        />
        <CheckDontUseIpSpeakerModal v-if="isCheckDontUseIpSpeakerModal" @closeModal="[isCheckDontUseIpSpeakerModal = false, isIpSpeaker = true]"/>
        <UpdateSpaceModal v-if="updateSpaceStatus" @closeUpdateSpace="updateSpaceStatus=false" />
        <UpdateCancelCheck v-if="updateCancelStatus" @closeCheckModal="updateCancelStatus=false" @back="back"/>
        <Unsubscribe v-if="ShowUnsub" :spaceInfo="spaceInfo" @closeModal="ShowUnsub = false" @expireDate="getExpireDate" @openFail="isCancelPaymentFail = true" @openRefund="isCancelPaymentRefund = true" @openCancel="isCancelPayment = true"/>
        <CancelPayment v-if="isCancelPayment" :spaceInfo="spaceInfo" :expireDate="expireDate" @closeModal="isCancelPayment = false"/>
        <CancelPaymentRefund v-if="isCancelPaymentRefund" :spaceInfo="spaceInfo" @closeModal="isCancelPaymentRefund = false"/>
        <CancelPaymentFail v-if="isCancelPaymentFail" :spaceInfo="spaceInfo" @closeModal="isCancelPaymentFail = false"/>
        <div class="space_pc" style="overflow:auto;">
            <StartPc v-if="startStatus" :userInfo="userInfo" @insertPc="inserStartTime" @closePc="startStatus=false"/>
            <EndPc v-if="endStatus" :userInfo="userInfo" @insertPc="inserEndTime" @closePc="endStatus=false"/>
            <section class="main myspace w_940">
                <div class="main_box">
                    <div class="main_box_title" style="padding-bottom: 42px;">
                        <h1>스페이스 설정</h1>
                        <div @click="clickPre()">
                            <svg
                                width="19"
                                height="19"
                                viewBox="0 0 19 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </div>
                    <div class="cs_main">
                        <div class="myspace_content_form">
                            <form>
                                <label
                                >매장명<span>*</span>
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="매장명을 검색해주세요."
                                            v-model="spaceInfo.spaceNm"
                                            @keyup.enter="showMap"
                                            v-if="insertStutus === 'map'"
                                            disabled
                                        />
                                        <input
                                            type="text"
                                            placeholder="매장명을 검색해주세요."
                                            v-model="spaceInfo.spaceNm"
                                            v-else
                                            disabled
                                        />
                                        <!-- <div class="input_btn">
                      <p
                        id="store_search"
                        class="on"
                        style="margin-bottom: 0px"
                        @click="searchAddr('map')"
                      >
                        매장검색
                      </p>
                      <p style="margin-bottom: 0px" @click="searchAddr('search')">
                        직접입력
                      </p>
                    </div> -->
                                    </div>
                                </label>
                                <label
                                >매장주소 <span>*</span>
                                    <div>
                                        <input
                                            type="text"
                                            id="address"
                                            placeholder="매장명을 선택하면 자동으로 기입됩니다."
                                            v-model="spaceInfo.spaceAddr"
                                            :disabled="true"
                                            class="on"
                                        />
                                        <!-- <p class="search-addr" @click="searchAddress()">주소검색</p> -->
                                        <input
                                            type="text"
                                            id="addressDetail"
                                            v-model="spaceInfo.spaceAddrDetail"
                                            placeholder="상세주소를 입력해주세요."
                                            disabled
                                        />
                                    </div>
                                </label>
                                <label style="position: relative;">
                                    소속 브랜드
                                    <div>
                                        <!-- <img src="/media/img/search_icon.svg" alt="" style="position: absolute; top: 50%; left: 1%;"/> -->
                                        <input type="text" placeholder="브랜드를 등록해주세요." v-model="brandName" disabled>
                                    </div>
                                </label>
                                <label id="tel"
                                >연락처
                                    <div>
                                        <input
                                            type="text"
                                            maxlength="11"
                                            placeholder="매장 연락처를  - 없이 입력해주세요."
                                            v-model="spaceInfo.spaceTel"
                                            @input="numCheck()"
                                        />
                                    </div>
                                </label>
                                <label
                                >사업자등록번호
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="사업자등록번호를  - 없이 입력해주세요."
                                            v-model="spaceInfo.bno"
                                            @keyup.enter="checkBno()"
                                        />
                                        <p class="btn_type" @click="checkBno()">확인</p>
                                    </div>
                                </label>
                                <div>
                                    <label>업종 <span>*</span></label>
                                    <div class="select_box">
                                        <div class="select-box-area">
                                            <div
                                                class="label select-box"
                                                @mouseover.once="clickSelectBox()"
                                                v-if="spaceInfo.sector == null"
                                            >
                                                대분류
                                            </div>
                                            <div class="label select-box" @mouseover.once="clickSelectBox()" v-else>
                                                {{ spaceInfo.sector }}
                                            </div>
                                            <div class="select-box-dropDown">
                                                <ul>
                                                    <li
                                                        v-for="item in sectors"
                                                        :key="item.setorId"
                                                        @click.once="getSectorDetails(item.sectorId)"
                                                        class="optionItem"
                                                    >
                                                        <p>{{ item.sectorNm }}</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="select-box-area2">
                                            <div
                                                class="label2 select-box2"
                                                @mouseover.once="clickSelectBox2()"
                                                v-if="spaceInfo.sectorDetail == null"
                                            >
                                                소분류
                                            </div>
                                            <div class="label2 select-box2" @mouseover.once="clickSelectBox2()" v-else>
                                                {{ spaceInfo.sectorDetail }}
                                            </div>
                                            <div class="select-box-dropDown2">
                                                <ul>
                                                    <li
                                                        v-for="item in sectorDetails"
                                                        :key="item.sectorDetailId"
                                                        class="optionItem2"
                                                    >
                                                        <p>{{ item.sectorDetailNm }}</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <label
                                >영업시간 <span>*</span>
                                    <div class="set_clock">
                                        <div class="start_clock" @click="selectStartTime">
                                            <p style="margin-bottom: 0px">
                                                {{ userInfo.startTime }}
                                            </p>
                                        </div>
                                        ~
                                        <div class="end_clock" @click="selectEndTime">
                                            <p style="margin-bottom: 0px">
                                                {{ userInfo.endTime }}
                                            </p>
                                        </div>
                                    </div>
                                </label>
                                <label>IP 스피커 사용여부 <span>*</span>
                                    <div style="display: flex; justify-content: flex-start; align-items: center;" @click="checkHasSpaceDontUseIpSpeaker()">
                                        <checkboxUseIpSpeaker v-model="isIpSpeaker" />
                                        <p style="margin:0 6px 0;">사용</p>
                                    </div>
                                </label>
                            </form>
                            <div class="form_bottom">
                                <p id="delet_space" style="margin-bottmo:0px;" @click="ShowUnsub = true">구독 해지</p>
                                <div>
                                    <!-- <button id="prev_btn" style="text-align: center" @click="linkMySpace()">취소</button> -->
                                    <button
                                        style="text-align: center"
                                        v-if="spaceInfo.spaceNm != null
                                            && spaceInfo.spaceNm.length > 0
                                            && spaceInfo.spaceAddr != null
                                            && spaceInfo.spaceAddr !== ''
                                            && spaceInfo.sector != null
                                            && spaceInfo.sectorDetail != null
                                            && spaceInfo.sectorDetail !== '소분류'
                                            &&  spaceInfo.startDate != null
                                            && spaceInfo.endDate != null"
                                        @click="updateSpaceInfo()"
                                    >
                                        확인
                                    </button>
                                    <button v-else style="text-align: center; color: #5B5B5B; background: #4D1B24" disabled>확인</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- 모바일 -->
        <div class="space_mo">
            <StartMobile v-if="startStatus" :userInfo="userInfo" @insertMobile="inserStartTime" @closeMobile="startStatus=false"/>
            <EndMobile v-if="endStatus" :userInfo="userInfo" @insertMobile="inserEndTime" @closeMobile="endStatus=false"/>
            <div class="mo_space_header">
                <div class="mo_header_box">
                    <a>
                        <img src="/media/images/icon_back_24.svg" @click="clickPre()">
                    </a>
                    <h2 style="margin-bottom:0px;">스페이스 설정</h2>
                    <div class="btn_close" @click="clickPre()">
                        <img src="/media/images/icon_close_24.svg">
                    </div>
                </div>
            </div>
            <section class="main myspace">
                <div class="main_box">
                    <div class="cs_main">
                        <div class="myspace_content_form bt_pd">
                            <form>
                                <label
                                >매장명 <span>*</span>
                                    <div>
                                        <input type="text" placeholder="매장명을 검색해주세요." v-model="spaceInfo.spaceNm" @keyup.enter="showMap" v-if="insertStutus === 'map'" disabled/>
                                        <input type="text" placeholder="매장명을 검색해주세요." v-model="spaceInfo.spaceNm" v-else disabled/>
                                        <!-- <div class="input_btn">
                      <p id="store_search1" class="on" style="margin:4px 8px;" @click="searchAddr('map')">매장검색</p>
                      <p style="margin-bottom:4px;margin-top:4px;" @click="searchAddr('search')">직접입력</p>
                    </div> -->
                                    </div>
                                </label>
                                <label
                                >매장주소 <span>*</span>
                                    <div>
                                        <input
                                            type="text"
                                            id="address2"
                                            placeholder="매장명을 선택하면 자동으로 기입됩니다."
                                            v-model="spaceInfo.spaceAddr"
                                            :disabled="true"
                                        />
                                        <!-- <div class="input_btn2">
                      <p style="margin-bottom: 0px" @click="searchAddress()">주소검색</p>
                    </div> -->
                                        <input type="text" id="addressDetail2" v-model="spaceInfo.spaceAddrDetail" placeholder="상세주소를 입력해주세요." disabled/>
                                    </div>
                                </label>
                                <label style="position: relative;">
                                    소속 브랜드
                                    <div>
                                        <!-- <img src="/media/img/search_icon.svg" alt="" style="position: absolute; top: 45%; left: 2%;"/> -->
                                        <input type="text" placeholder="브랜드를 등록해주세요." v-model="brandName" disabled/>
                                    </div>
                                </label>
                                <label id="tel"
                                >연락처
                                    <div>
                                        <input
                                            type="text"
                                            maxlength="11"
                                            placeholder="매장 연락처를  - 없이 입력해주세요."
                                            v-model="spaceInfo.spaceTel"
                                            @input="numCheck()"
                                        />
                                    </div>
                                </label>
                                <label
                                >사업자등록번호
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="사업자등록번호를  - 없이 입력해주세요."
                                            v-model="spaceInfo.bno"
                                            @keyup.enter="checkBno()"
                                        />
                                        <p class="btn_type" @click="checkBno()">확인</p>
                                    </div>
                                </label>
                                <div>
                                    <label>업종 <span>*</span></label>
                                    <div class="select_box">
                                        <div class="select-box-area">
                                            <div class="select-box select_box_big" v-if="spaceInfo.sector == null" @click.once="clickSelectBoxMo()">대분류</div>
                                            <div class="select-box select_box_big" v-else @click.once="clickSelectBoxMo()">{{ spaceInfo.sector }}</div>
                                        </div>
                                        <div class="select-box-area">
                                            <div class="select-box select_box_small" v-if="spaceInfo.sectorDetail == null" @click.once="clickSelectBoxMo2()">소분류</div>
                                            <div class="select-box select_box_small" v-else @click.once="clickSelectBoxMo2()">{{ spaceInfo.sectorDetail }}</div>
                                        </div>
                                    </div>
                                </div>
                                <label
                                >영업시간 <span>*</span>
                                    <div class="set_clock">
                                        <div class="start_clock" @click="selectStartTime">
                                            <p style="margin-bottom:0px;">{{ userInfo.startTime }}</p>
                                        </div>
                                        ~
                                        <div class="end_clock" @click="selectEndTime">
                                            <p style="margin-bottom:0px;">{{ userInfo.endTime }}</p>
                                        </div>
                                    </div>
                                </label>
                                <label>IP 스피커 사용여부 <span>*</span>
                                    <div style="display: flex; justify-content: flex-start; align-items: center;">
                                        <checkboxUseIpSpeaker v-model="isIpSpeaker" />
                                        <p style="margin:0 6px 0;">사용</p>
                                    </div>
                                </label>
                            </form>
                            <p id="delet_space" style="margin-bottmo:0px; margin-top: 20px; color: white; font-size: 12px;" @click="ShowUnsub = true">구독 해지</p>
                            <div class="form_bottom">
                                <button style="text-align:center;"
                                        v-if="spaceInfo.spaceNm != null
                                            && spaceInfo.spaceNm.length > 0
                                            && spaceInfo.spaceAddr != null
                                            && spaceInfo.spaceAddr !== ''
                                            && spaceInfo.sector != null
                                            && spaceInfo.sectorDetail != null
                                            && spaceInfo.sectorDetail !== '소분류'
                                            &&  spaceInfo.startDate != null
                                            && spaceInfo.endDate != null"
                                        @click="updateSpaceInfo()">수정</button>
                                <button class="opacity3" style="text-align:center;" v-else>수정</button>
                            </div>
                            <div class="space_down_box1">
                                <div>
                                    <h2>대분류</h2>
                                    <svg @click.once="closeSelectBoxMo()"
                                         width="24"
                                         height="24"
                                         viewBox="0 0 24 24"
                                         fill="none"
                                         xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M12.0007 10.5862L16.9507 5.63623L18.3647 7.05023L13.4147 12.0002L18.3647 16.9502L16.9507 18.3642L12.0007 13.4142L7.05072 18.3642L5.63672 16.9502L10.5867 12.0002L5.63672 7.05023L7.05072 5.63623L12.0007 10.5862Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                                <ul>
                                    <li v-for="item in sectors" :key="item.sectorId" @click.once="[selectBoxMo(item.sectorId), getSectorDetails(item.sectorId)]">
                                        <p :id="item.sectorId" style="margin-bottom:0px;">{{ item.sectorNm }}</p>
                                    </li>
                                </ul>
                            </div>

                            <div class="space_down_box2">
                                <div>
                                    <h2>소분류</h2>
                                    <svg @click.once="closeSelectBoxMo2()"
                                         width="24"
                                         height="24"
                                         viewBox="0 0 24 24"
                                         fill="none"
                                         xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M12.0007 10.5862L16.9507 5.63623L18.3647 7.05023L13.4147 12.0002L18.3647 16.9502L16.9507 18.3642L12.0007 13.4142L7.05072 18.3642L5.63672 16.9502L10.5867 12.0002L5.63672 7.05023L7.05072 5.63623L12.0007 10.5862Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                                <ul>
                                    <li v-for="item in sectorDetails" :key="item.sectorDetailId" @click.once="selectBoxMo2(item.sectorDetailId)">
                                        <p :id="item.sectorDetailId" style="margin-bottom:0px;">{{ item.sectorDetailNm }}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import StartPc from "@/components/modal/AutoPlay/StartPc.vue";
import EndPc from "@/components/modal/AutoPlay/EndPc.vue";
import StartMobile from "@/components/modal/AutoPlay/StartMobile.vue";
import EndMobile from "@/components/modal/AutoPlay/EndMobile.vue";
import Unsubscribe from "@/components/modal/Space/Unsubscribe.vue";
import CancelPayment from "@/components/modal/Space/CancelPayment.vue";
import CancelPaymentRefund from "@/components/modal/Space/CancelPaymentRefund.vue";
import CancelPaymentFail from "@/components/modal/Space/CancelPaymentFail.vue";
import searchStore from "@/components/map/searchStore.vue";
import UpdateSpaceModal from "@/components/modal/Space/UpdateSpaceModal.vue";
import UpdateCancelCheck from "@/components/modal/Space/UpdateCancelCheck.vue";
import checkboxUseIpSpeaker from "@/components/checkbox/checkboxUseIpSpeaker.vue";
import CheckDontUseIpSpeakerModal from "@/components/modal/Space/CheckDontUseIpSpeakerModal.vue";
// import Floating from "@/components/Floating.vue";
import $ from "jquery";
import axios from "axios";
import VueCookies from "vue-cookies";
import dayjs from "dayjs";
// import router from "@/router";

export default defineComponent({
  data () {
    return {
      isMap: false,
      ShowUnsub: false,
      ShowUnsub2: false,
      sectors: [],
      sectorDetails: [],
      spaceInfo: {},
      spaceName: "",
      insertStutus: 'map',
      startStatus: false,
      endStatus: false,
      userInfo: {
        startTime: '09:00',
        endTime: '18:00'
      },
      updateSpaceStatus: false,
      updateCancelStatus: false,
      detailInfo: {},
      brandName: '',
      isCancelPayment: false,
      isCancelPaymentRefund: false,
      isCancelPaymentFail: false,
      expireDate: '',
      isIpSpeaker: true,
      isCheckDontUseIpSpeakerModal: false
    };
  },
  components: {
    Unsubscribe,
    searchStore,
    StartPc,
    EndPc,
    StartMobile,
    EndMobile,
    UpdateSpaceModal,
    UpdateCancelCheck,
    // Floating,
    CancelPayment,
    CancelPaymentRefund,
    CancelPaymentFail,
    checkboxUseIpSpeaker,
    CheckDontUseIpSpeakerModal
  },
  methods: {
    numCheck () {
      this.spaceInfo.spaceTel = this.spaceInfo.spaceTel.replace(/[^0-9]/g, '');
    },
    updateSpaceIp () {
      var spaceId = this.spaceInfo.spaceId;
      var spaceIp = null;
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/profile/insertSpaceIp", { spaceId, spaceIp }, { headers })
        .catch(err => {
          console.log(err);
        });
    },
    checkHasSpaceDontUseIpSpeaker () {
      var userId = VueCookies.get("userId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/profile/hasSpaceDontUseIpSpeaker", { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            if (this.isIpSpeaker) {
              res.data.result === 1 ? this.isCheckDontUseIpSpeakerModal = true : this.isIpSpeaker = false;
            } else {
              this.isIpSpeaker = true;
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getExpireDate (date) {
      this.expireDate = this.dateTime2(date);
    },
    searchBrand () {
      this.franchiseStatus = true;
    },
    updateSpaceInfo () {
      // 매장 정보 수정
      var noticeTypeDetail = VueCookies.get('userId');
      this.isIpSpeaker === true ? this.spaceInfo.useIpSpeaker = 'Y' : this.spaceInfo.useIpSpeaker = 'N';
      var spaceInfo = this.spaceInfo;
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };
      VueCookies.set("spaceNm", spaceInfo.spaceNm); // 스페이스명 쿠키등록

      axios
        .post("/api/profile/updateSpaceInfo", spaceInfo, { headers })
        .then((res) => {
          // console.log(res.data.result);
          var spaceId = spaceInfo.spaceId;

          if (this.spaceInfo.useIpSpeaker === 'N' && this.spaceInfo.spaceIp != null) this.updateSpaceIp();

          axios
            .post('/api/menu/updateSpaceNotice', { spaceId, noticeTypeDetail }, { headers })
            .then(res => {
              // console.log(res.data.result);
              this.updateSpaceStatus = true;
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getSpaceInfo () {
      // 등록된 매장정보 가져오기
      var spaceId = localStorage.getItem("spaceId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      await axios
        .post("/api/profile/getMySpaceInfo", { spaceId }, { headers })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            var result = res.data.result;
            this.spaceInfo = result;
            result.useIpSpeaker === 'Y' ? this.isIpSpeaker = true : this.isIpSpeaker = false;
            this.getBrandInfo();
            this.getSectors();

            if (this.spaceInfo.startDate != null) {
              this.userInfo.startTime = this.spaceInfo.startDate;
            } else {
              this.userInfo.startTime = '09:00';
            }
            if (this.spaceInfo.endDate != null) {
              this.userInfo.endTime = this.spaceInfo.endDate;
            } else {
              this.userInfo.endTime = '18:00';
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBrandInfo () {
      var brandId = this.spaceInfo.brandId;
      if (brandId != null) {
        var aToken = VueCookies.get("aToken");
        var headers = { "X-AUTH-TOKEN": aToken };

        axios
          .post('/api/profile/getBrand', { brandId }, { headers })
          .then(res => {
            // console.log(res.data.result);
            this.brandName = res.data.result.brandNm;
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    clickPre () {
      this.updateCancelStatus = true;
      // router.push({ name: "MySpace" });
    },
    back () {
      history.back();
    },
    setSpaceInfo (spaceInfo) {
      // 매장검색을 통해 매장명, 주소 값 받기
      this.spaceInfo.spaceNm = spaceInfo.spaceNm;
      this.spaceInfo.spaceAddr = spaceInfo.spaceAddr;
      this.spaceInfo.spaceAddrDetail = "";
    },
    showMap () { // 매장검색 모달 열기
      this.spaceName = this.spaceInfo.spaceNm;
      this.isMap = true;
      $('.search-addr').hide();
      $('.input_btn2').hide();
    },
    searchAddr (select) { // 클릭한 버튼 색상 변경
      this.insertStutus = select;
      $(document).on('click', '.input_btn p', function () {
        $(".input_btn").find('p').removeClass('on');
        $(this).addClass('on');
      });

      if (select === 'map') {
        this.showMap();
      } else {
        $('.search-addr').show();
        $('.input_btn2').show();
      }
    },
    searchAddress () {
      // 주소검색
      new window.daum.Postcode({
        oncomplete: (data) => {
          var addr = "";

          // 사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
          if (data.userSelectedType === "R") {
            // 사용자가 도로명 주소를 선택했을 경우
            addr = data.roadAddress;
          } else {
            // 사용자가 지번 주소를 선택했을 경우(J)
            addr = data.jibunAddress;
          }
          // 주소 정보를 해당 필드에 넣는다.
          document.getElementById("address").value = addr;
          // 커서를 상세주소 필드로 이동한다.
          document.getElementById("addressDetail").focus();
          this.spaceInfo.spaceAddrDetail = "";
          this.spaceInfo.spaceAddr = addr;
        }
      }).open();
    },
    checkBno () {
      // 사업자등록번호 체크
      var bzno = this.spaceInfo.bno;
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/user/getBzno", { bzno }, { headers })
        .then((res) => {
          // console.log(res.data.result);
          var result = res.data.result;
          if (result === 1) {
            alert("정상적인 사업자등록번호 입니다.");
          } else if (result === 2) {
            alert("등록되지않은 사업자등록번호 입니다.");
            this.spaceInfo.bno = "";
          } else {
            alert("휴/폐업된 사업자등록번호 입니다.");
            this.spaceInfo.bno = "";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getSectors () {
      // 업종 목록 가져오기
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      await axios
        .post("/api/profile/getSectorList", null, { headers })
        .then((res) => {
          // console.log(res.data.result);
          this.sectors = res.data.result;

          var brandNm = this.spaceInfo.sector;
          for (var i in this.sectors) {
            var detailId = this.sectors[i].sectorNm.indexOf(brandNm);
            if (detailId === 0) {
              this.detailInfo = this.sectors[i];
              this.getSectorDetails(this.detailInfo.sectorId);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSectorDetails (item) {
      // 업종상세 목록 가져오기
      var sectorId = item;
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/profile/getSectorDetailList", { sectorId }, { headers })
        .then((res) => {
          // console.log(res.data.result);
          this.sectorDetails = res.data.result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    dateTime (value) {
      return dayjs(value).format("HH:mm");
    },
    dateTime2 (value) {
      return dayjs(value).format("YYYY.MM.DD");
    },
    clickSelectBox () {
      /* select_box_custom */
      var label = document.querySelectorAll('.label');
      label.forEach(function (lb) {
        lb.addEventListener('click', e => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //* ***************** 추가된 부분 START
        // 내가 아닌 다른 select 닫기
        label.forEach(function (itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //* ***************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach((opt) => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          optionItems.forEach((opt) => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        this.spaceInfo.sector = item.textContent;
        this.spaceInfo.sectorDetail = "소분류";
        // label.innerHTML = item.textContent;
        label.parentNode.classList.remove('active');
      };
      var handleClose = e => {
        // select 가 아닌경우 닫기
        if (!e.target.classList.contains('label') && !e.target.classList.contains('optionItem')) {
          label.forEach(function (lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', e => handleClose(e));
    },
    clickSelectBox2 () {
      /* select_box_custom */
      var label = document.querySelectorAll('.label2');
      label.forEach(function (lb) {
        lb.addEventListener('click', e => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem2');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //* ***************** 추가된 부분 START
        // 내가 아닌 다른 select 닫기
        label.forEach(function (itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //* ***************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach((opt) => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          optionItems.forEach((opt) => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        this.spaceInfo.sectorDetail = item.textContent;
        // label.innerHTML = item.textContent;
        label.parentNode.classList.remove('active');
      };
      var handleClose = e => {
        // select 가 아닌경우 닫기
        if (!e.target.classList.contains('label2') && !e.target.classList.contains('optionItem2')) {
          label.forEach(function (lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', e => handleClose(e));
    },
    clickSelectBoxMo () {
      $(document).off("click").on("click", ".select_box_big", () => {
        $(".space_down_box1").css({ bottom: 0, transition: "all 0.5s" });
        $(".back_dim").show();
      });
    },
    closeSelectBoxMo () {
      $(".space_down_box1").find('svg').click(function () {
        $(".space_down_box1").css({ bottom: "-100%", transition: "all 1s" });
        $(".back_dim").hide();
      });
    },
    selectBoxMo (index) {
      this.spaceInfo.sector = document.getElementById(index).innerHTML;
      if (this.spaceInfo.sectorDetail != null) {
        this.spaceInfo.sectorDetail = "소분류";
      }
      $(".space_down_box1").css({ bottom: "-100%", transition: "all 1s" });
      $(".back_dim").hide();
    },
    clickSelectBoxMo2 () {
      $(".select_box_small").click(function () {
        $(".space_down_box2").css({ bottom: "0", transition: "all 0.5s" });
        $(".back_dim").show();
      });
    },
    closeSelectBoxMo2 () {
      $(".space_down_box2").find('svg').click(function () {
        $(".space_down_box2").css({ bottom: "-100%", transition: "all 1s" });
        $(".back_dim").hide();
      });
    },
    selectBoxMo2 (index) {
      this.spaceInfo.sectorDetail = document.getElementById(index).innerHTML;
      $(".space_down_box2").css({ bottom: "-100%", transition: "all 1s" });
      $(".back_dim").hide();
    },
    getDaumcdn () {
      const script = document.createElement("script");
      script.src = "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
      document.head.appendChild(script);
    },
    set () {
      $('.search-addr').hide();
      $('.input_btn2').hide();
    },
    selectStartTime () {
      this.startStatus = true;
    },
    selectEndTime () {
      this.endStatus = true;
    },
    inserStartTime (time) {
      var hour = time.hour.substring(0, 2);
      var minute = time.minute.substring(0, 2);
      this.userInfo.startTime = hour + ':' + minute;
      this.spaceInfo.startDate = this.userInfo.startTime;
      this.startStatus = false;
    },
    inserEndTime (time) {
      var hour = time.hour.substring(0, 2);
      var minute = time.minute.substring(0, 2);
      this.userInfo.endTime = hour + ':' + minute;
      this.spaceInfo.endDate = this.userInfo.endTime;
      this.endStatus = false;
    }
  },
  async mounted () {
    await this.getSpaceInfo();
    this.getDaumcdn();
    this.set();
  }
});
</script>
<style scoped>
.modal {
  display: block;
}
.myspace_content_form .input_btn p.on{
  background-color: white;
  color: black;
}
.myspace_content_form .input_btn p.on:hover{
  opacity:0.8;
}
.myspace_content_form .search-addr {
  position:absolute;
  top:25%;
  right:2px;
  transform:translateY(-60%);
  display:flex;
  justify-content:center;
  align-items:center;
}
.myspace_content_form .search-addr {
  padding:8px 16px;
  background:transparent;
  color:#fff;
  border-radius:8px;
  cursor:pointer;
  transition:all 0.3s;
}
.myspace_content_form .search-addr {
  margin-right:7px;
  background:#fff;
  color:#151515;
}
.myspace_content_form .search-addr:hover {
  background:rgba(255,255,255,0.8);
}
.input_btn2 {
    position: absolute;
    top: 25%;
    right: 10px;
    transform: translateY(-60%);
    display: flex;
    justify-content: center;
    align-items: center;
}
.input_btn2 {
  padding: 8px 12px;
  background: white;
  color: black;
  border-radius: 8px;
}
@media all and (max-width: 500px) {
  input {

  }
  .select-box-dropDown ul {
    padding: 0;
  }
  .space_down_box1 ul li {
    margin-bottom: 12px;
  }
  .space_down_box2 ul li {
    margin-bottom: 12px;
  }
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
