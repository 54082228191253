<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>
                수정한 정보가 저장되지 않습니다.<br>
                그래도 닫으시겠습니까?
            </h3>
            <div class="single-file-btn">
                <p @click="checkCancel">아니오</p>
                <p id="color-text" @click="linkMySpace">네</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'updateCancelCheck',
  methods: {
    linkMySpace () {
      this.$emit('closeCheckModal');
      this.$emit('back');
    },
    checkCancel () {
      this.$emit('closeCheckModal');
    }
  }
};
</script>
